import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import Callout from './Callout';

function GettingStartedIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-2">
            <h1 className="font-bold text-4xl pb-2">Get started</h1>
            <h2 className="font-bold text-2xl pt-4">EDSL</h2>
            <p>
                EDSL is our open-source Python library for conducting AI-powered
                research. EDSL simplifies creating and running surveys with
                large numbers of AI agents. EDSL also supports other types of
                workflows including experiments and data labeling tasks.
            </p>
            <Callout title="Tip">
                See the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/overview.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    EDSL Docs
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
                for in-depth documentation, useful templates, and tutorials.
                <br></br>
                For first time users, we suggest the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    Starter tutorial
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>
                .
            </Callout>
            <h2 className="font-bold text-2xl pt-6">Coop</h2>
            <p>
                Coop is our web app for sharing, exploring, and conducting
                AI-powered research. You can use Coop to store and retrieve EDSL
                objects, cache their LLM calls online to make them accessible
                everywhere, or use remote inference to run your workflows on
                Coop. Coop also allows you to create AI-powered research without
                writing any code!
            </p>
            <p>
                To create an account and get started, simply click on the
                "Login" button on the upper right of this page!
            </p>
            <Callout title="Tip">
                See the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/coop.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    Coop docs
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
                for examples.
            </Callout>
            <div className="space-y-2 pt-6">
                <h1 className="font-bold text-2xl">Community</h1>
                <p>
                    Need help? Have a feature request? Want to talk to others
                    with a passion for AI-powered research? Join the Expected
                    Parrot community!
                </p>
                <ul className="space-y-2">
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Join our{' '}
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Discord channel
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Check out our{' '}
                            <a
                                href="https://blog.expectedparrot.com"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Blog
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Follow us on{' '}
                            <a
                                href="https://x.com/expectedparrot"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Twitter / X
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Follow us on{' '}
                            <a
                                href="https://www.linkedin.com/company/expectedparrot"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                LinkedIn
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Email us at{' '}
                            <a
                                href="mailto:info@expectedparrot.com"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                info@expectedparrot.com
                            </a>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default GettingStartedIndex;
