import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import Callout from './Callout';

function GettingStartedIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-2">
            <h1 className="font-bold text-4xl pb-2">Get started</h1>
            <p className="text-lg">
                This page is designed to help you get started using our tools
                for conducting AI-powered research.
            </p>
            <h2 className="font-bold text-2xl pb-2 pt-6">EDSL</h2>
            <p>
                EDSL (<i>Expected Parrot Domain-Specific Language</i>) is an
                open-source Python library for conducting AI-powered research.
                EDSL simplifies the creation and execution of surveys,
                experiments, data labeling tasks and other research activities
                involving large numbers of AI agents and language models. Its
                primary goal is to facilitate complex AI-based research tasks
                with ease and efficiency.
            </p>
            <p>
                Please see instructions on{' '}
                <Link
                    to="/getting-started/edsl-installation"
                    className={`${linkStyles}`}
                >
                    installing EDSL
                </Link>{' '}
                and storing your{' '}
                <Link
                    to="/getting-started/edsl-api-keys"
                    className={`${linkStyles}`}
                >
                    API keys for LLMs
                </Link>
                . Then explore a{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                    target="_blank"
                    className={`${linkStyles}`}
                >
                    Starter Tutorial
                </a>{' '}
                and other resources in the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/overview.html"
                    target="_blank"
                    className={`${linkStyles}`}
                >
                    EDSL Docs
                </a>
                .
            </p>
            <br />
            <Callout title="Tip">
                Please see the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/overview.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    EDSL Docs
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
                for an overview, templates, tutorials and in-depth documentation
                for the EDSL library.
            </Callout>
            <h2 className="font-bold text-2xl pb-2 pt-6">Coop</h2>
            <p>
                The Coop (as in <i>bird coop</i> and also <i>cooperative</i>) is
                a web application for storing and sharing your work and
                exploring other AI-powered research. Use the Coop to store and
                retrieve EDSL content, use remote caching to save your LLM call
                results, and use remote inference to run your workflows on Coop
                (consumes credits).
            </p>
            <p>
                Learn more about the Coop and how to use it by{' '}
                <a href="https://www.expectedparrot.com/login">
                    creating an account
                </a>{' '}
                and exploring examples in the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/coop.html"
                    target="_blank"
                    className={`${linkStyles}`}
                >
                    Coop documentation page
                </a>
                .
            </p>
            <p className="italic">
                Coop is still in development. Let us know if you're interested
                in trying it out!
            </p>
            <div className="space-y-4 pt-6">
                <h1 className="font-bold text-2xl">Community</h1>
                <p>
                    Need help? Have a feature request? Want to talk to others
                    with a passion for AI-powered research? Join the Expected
                    Parrot community!
                </p>
                <ul className="space-y-2">
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Join our{' '}
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Discord channel
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Check out our{' '}
                            <a
                                href="https://blog.expectedparrot.com"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Blog
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Follow us on{' '}
                            <a
                                href="https://x.com/expectedparrot"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                Twitter / X
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Follow us on{' '}
                            <a
                                href="https://www.linkedin.com/company/expectedparrot"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                LinkedIn
                            </a>
                        </p>
                    </li>
                    <li className="flex">
                        <span className="mr-2">&bull;</span>
                        <p>
                            Email us at{' '}
                            <a
                                href="mailto:info@expectedparrot.com"
                                target="_blank"
                                className={`${linkStyles}`}
                            >
                                info@expectedparrot.com
                            </a>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default GettingStartedIndex;
