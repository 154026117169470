export async function action({ request }) {
    const formData = await request.formData();

    const response = await fetch('/api/projects/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name: formData.get('name'),
            survey_uuid: formData.get('survey_uuid'),
        }),
    });

    if (response.ok) {
        const successData = await response.json();
        return successData.message;
    } else if (response.status === 422) {
        return 'Invalid data. Please double-check to make sure you entered a valid UUID!';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
