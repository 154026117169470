export * from './Admin';
export { default } from './Admin';
export {
    default as AdminLogin,
    loader as adminLoginLoader,
} from './routes/Login';
export {
    default as AdminHome,
    loader as adminHomeLoader,
    action as adminHomeAction,
} from './routes/Home';
export {
    default as AdminObjects,
    loader as adminObjectsLoader,
    action as adminObjectsAction,
} from './routes/Objects';
export {
    default as AdminStaleObjects,
    action as adminStaleObjectsAction,
} from './routes/StaleObjects';
export {
    default as AdminUserStatistics,
    loader as adminUserStatisticsLoader,
} from './routes/UserStatistics';
