export async function action({ request }) {
    const formData = await request.formData();

    const response = await fetch('/api/register', {
        method: 'POST',
        body: formData,
    });

    if (response.ok) {
        return 'Please verify your email address.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
