import { Link, useLoaderData } from 'react-router-dom';
import { Calendar, ExternalLink } from 'react-feather';
import * as Tabs from '@radix-ui/react-tabs';
import ComparisonChart from './ComparisonChart';
import NewJobModal from './NewJobModal';
import DataTable from '../../../../../Content/routes/ContentId/ObjectData/DataTable';
import Question from '../../../../../Content/routes/ContentId/ObjectData/Question';
import SurveyFlow from '../../../../../Content/routes/ContentId/ObjectData/SurveyFlow';
import ObjectTypeChip from '../../../../../base/ObjectTypeChip';
import RemoteJobTable from '../../../../../base/RemoteJobTable';

interface Response {
    user_id: number;
    [key: string]: any;
}

interface ProjectData {
    project: {
        id: string;
        name: string;
        created_time_from_now: string;
        jobs: {
            created_ts: number;
            last_queued_ts: number;
            job_uuid: string;
            description: string;
            results_status: string;
            results_uuid: string;
            price: number;
            status: string;
            logs: {
                created_ts: number;
                price: number;
                status: string;
                reason: string;
                description: string;
            }[];
        }[];
        survey: {
            id: string;
            description: string;
            data: {
                questions: {
                    question_name: string;
                    question_options?: string[];
                    question_text: string;
                    question_type: string;
                }[];
                rule_collection: {
                    rules: {
                        current_q: number;
                        expression: string;
                        next_q: number;
                        priority: number;
                    }[];
                };
            };
        };
        agent_responses: {
            columns: {
                [key: string]: boolean;
            };
            records: Response[];
        };
        human_responses: {
            columns: {
                [key: string]: boolean;
            };
            records: Response[];
        };
        response_counts: {
            [questionName: string]: {
                name: string;
                human: number;
                agent: number;
            }[];
        };
    };
}

function ProjectId() {
    const { project } = useLoaderData() as ProjectData;

    return (
        <>
            <h1 className="font-medium text-lg">{project.name}</h1>
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                <Calendar className="w-4 h-4 mr-2" />
                <span>Created {project.created_time_from_now}</span>
            </div>
            <p className="space-x-3 text-sm">
                <ObjectTypeChip objectType="survey" />
                <span>{project.survey.description}</span>
            </p>
            <div>
                <Link
                    className="inline-flex items-center px-4 py-2.5 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                    target="_blank"
                    to={`/respond/${project.id}`}
                >
                    <ExternalLink className="w-5 h-5 mr-3" />
                    Survey preview
                </Link>
            </div>
            <div className="p-6 border-2 border-gray-200 dark:border-primary-dark-border shadow-lg rounded-xl relative">
                <Tabs.Root defaultValue="human_responses">
                    <Tabs.List
                        className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                        aria-label="Switch tabs"
                    >
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="human_responses"
                        >
                            Human responses
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="agent_responses"
                        >
                            Agent responses
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="comparison"
                        >
                            Comparison
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="questions"
                        >
                            Questions
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="flow"
                        >
                            Flow
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                            value="jobs"
                        >
                            Jobs
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content value="human_responses">
                        <div className="space-y-4 py-3">
                            {project.human_responses.records.length > 0 ? (
                                <DataTable
                                    columns={project.human_responses.columns}
                                    records={project.human_responses.records}
                                />
                            ) : (
                                <p>No responses yet!</p>
                            )}
                        </div>
                    </Tabs.Content>
                    <Tabs.Content value="agent_responses">
                        <div className="space-y-4 py-3">
                            {project.agent_responses.records.length > 0 ? (
                                <DataTable
                                    columns={project.agent_responses.columns}
                                    records={project.agent_responses.records}
                                />
                            ) : (
                                <p>No responses yet!</p>
                            )}
                        </div>
                    </Tabs.Content>
                    <Tabs.Content value="comparison">
                        <div className="space-y-8 py-3">
                            {project.survey.data.questions.map((question, index) => {
                                const questionData = project.response_counts[question.question_name];
                                if (
                                    ['multiple_choice', 'yes_no', 'likert_five'].includes(question.question_type) &&
                                    questionData
                                ) {
                                    return (
                                        <div key={index} className="space-y-4">
                                            <h3 className="text-lg font-medium">{question.question_text}</h3>
                                            <div className="h-64">
                                                <ComparisonChart data={questionData} />
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </Tabs.Content>
                    <Tabs.Content value="questions">
                        <div className="space-y-4 py-3">
                            {project.survey.data.questions.map(
                                (question, index) => (
                                    <Question
                                        key={index}
                                        questionOptions={
                                            question.question_options
                                        }
                                        questionText={question.question_text}
                                        questionType={question.question_type}
                                    />
                                )
                            )}
                        </div>
                    </Tabs.Content>
                    <Tabs.Content value="flow">
                        <SurveyFlow
                            questions={project.survey.data.questions}
                            rule_collection={
                                project.survey.data.rule_collection
                            }
                        />
                    </Tabs.Content>
                    <Tabs.Content value="jobs">
                        <NewJobModal projectId={project.id}>
                            <div className="flex justify-start">
                                <button className="inline-flex items-center mb-2 px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm">
                                    Run with agents
                                </button>
                            </div>
                        </NewJobModal>
                        {project.jobs.length > 0 ? (
                            <div className="overflow-x-scroll">
                                <RemoteJobTable jobs={project.jobs} />
                            </div>
                        ) : (
                            <p>No jobs to show here!</p>
                        )}
                    </Tabs.Content>
                </Tabs.Root>
            </div>
        </>
    );
}

export default ProjectId;
