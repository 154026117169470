import { Link, useLoaderData } from 'react-router-dom';
import ObjectTable, { ObjectTableWrapper } from '../../../base/ObjectTable';
import { ObjectTableData } from '../../../../types';

function ContentIndex() {
    const data = useLoaderData() as ObjectTableData;

    function getTableOrMessage() {
        if (data.results_status === 'no content uploaded') {
            return (
                <div className="text-2xl text-center space-y-4">
                    <p>You haven't uploaded any objects 😔</p>
                    <p>
                        How about grabbing an{' '}
                        <Link
                            to="/home/api"
                            className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                        >
                            API key
                        </Link>
                        ?
                    </p>
                </div>
            );
        } else if (data.results_status === 'no results found') {
            return <p className="text-2xl text-center">No results found 😔</p>;
        } else {
            return (
                <ObjectTable
                    pageType="home"
                    objects={data.objects}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
            );
        }
    }

    return (
        <div className="flex flex-col">
            <ObjectTableWrapper pageType="home" owners={data.owners}>
                {getTableOrMessage()}
            </ObjectTableWrapper>
        </div>
    );
}

export default ContentIndex;
