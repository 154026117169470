import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import Callout from '../../Callout';
import InlineCode from '../../InlineCode';
import CodeBlock from '../../CodeBlock';

function EDSLAPIKeys() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">API Keys for LLMs</h1>
                <p>
                    API keys are required in order to use EDSL with large
                    language models (LLMs) such as OpenAI's GPTs, Google's
                    Gemini, Anthropic's Claude, Llama 2 and other models.
                </p>
                <p className="italic">
                    If you are looking for information about{' '}
                    <Link to="/home/api">
                        your API key for accessing the Coop
                    </Link>
                    , please{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        see instructions here
                    </Link>
                    .
                </p>
                <br />
                <p>
                    There are two methods for storing your API keys to use them
                    with EDSL:
                </p>
            </div>
            <div className="space-y-2">
                <h1 className="font-bold text-2xl">
                    1. Store your API keys in a <InlineCode>.env</InlineCode>{' '}
                    file (<i>recommended</i>)
                </h1>
                <p>
                    This method allows you to store your keys once instead of
                    providing them each time you start a new session with EDSL.
                </p>
                <p>
                    Create a file named <InlineCode>.env</InlineCode> in your
                    current working directory and populate it with your API keys
                    as shown below.
                </p>
                <p>
                    Replace <InlineCode>'your_key_here'</InlineCode> with your
                    actual API key for each service that you plan to use:
                </p>
                <CodeBlock>
                    <span className="">ANTHROPIC_API_KEY</span> =
                    'your_key_here'
                    <br />
                    <span className="">DEEP_INFRA_API_KEY</span> =
                    'your_key_here'
                    <br />
                    <span className="">GOOGLE_API_KEY</span> = 'your_key_here'
                    <br />
                    <span className="">OPENAI_API_KEY</span> = 'your_key_here'
                    <br />
                </CodeBlock>
            </div>
            <div className="space-y-2">
                <h1 className="font-bold text-2xl">
                    2. Setting API keys in your Python code
                </h1>
                <p>
                    Alternatively, you can set your API keys in your Python
                    script by running the following code before using EDSL. This
                    will store the keys in your system's memory only for the
                    duration of a session:
                </p>
                <CodeBlock>
                    <span className="text-blue-600 dark:text-sky-400">
                        import
                    </span>{' '}
                    os <br />
                    <br />
                    os.environ[
                    <span className="text-red-700 dark:text-lime-500">
                        'ANTHROPIC_API_KEY'
                    </span>
                    ] ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    os.environ[
                    <span className="text-red-700 dark:text-lime-500">
                        'DEEP_INFRA_API_KEY'
                    </span>
                    ] ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    os.environ[
                    <span className="text-red-700 dark:text-lime-500">
                        'GOOGLE_API_KEY'
                    </span>
                    ] ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    os.environ[
                    <span className="text-red-700 dark:text-lime-500">
                        'OPENAI_API_KEY'
                    </span>
                    ] ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                </CodeBlock>
            </div>
            <Callout title="Caution" style="caution">
                Your API keys are sensitive information, like passwords. Do not
                share them publicly.
            </Callout>
            <Callout title="Troubleshooting tips">
                See the{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/overview.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    EDSL Docs
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
                for troubleshooting tips, installation instructions, tutorials,
                templates and in-depth documentation for the components of the
                EDSL library.
            </Callout>
        </>
    );
}

export default EDSLAPIKeys;
