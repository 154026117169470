export async function action({ request }) {
    console.log('Form submitted to route action.');

    let data;
    let intent;

    const contentType = request.headers.get('Content-Type');

    if (contentType && contentType.includes('application/json')) {
        console.log('Handling JSON data...');
        data = await request.json();
        intent = data.intent;
    } else {
        console.log('Handling form data...');
        data = await request.formData();
        intent = data.get('intent');
    }

    let response: Response;

    if (intent === 'create_job') {
        const agentData = JSON.parse(data.agentData);
        response = await fetch(`/api/projects/create-job`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                project_uuid: data.projectId,
                traits: agentData.traits,
                agents: agentData.agents,
            }),
        });
    } else if (intent === 'retry_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'queued',
            }),
        });
    } else if (intent === 'cancel_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'cancelling',
            }),
        });
    }

    if (response.ok && intent === 'create_job') {
        const successData = await response.json();
        return successData.message;
    } else if (response.ok && intent === 'retry_job') {
        return 'Success! Job added to queue.';
    } else if (response.ok && intent === 'cancel_job') {
        return 'Success! Cancellation request sent.';
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
