import { Form, useLoaderData } from 'react-router-dom';
import { formatDate } from '../../../../utils';

interface Purchase {
    created_ts: number;
    provider: string;
    type: string;
    amount: number;
    quantity: number;
    invoice_url: string;
}

interface SubscriptionData {
    plan: string;
    expire_date: string;
    canceled: boolean;
}

interface PurchaseData {
    is_authenticated: boolean;
    purchases: Purchase[];
    applied_for_academics: boolean;
    subscription_data: SubscriptionData;
}

function Purchases() {
    const purchaseData = useLoaderData() as PurchaseData;

    function formatPrice(cents: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(cents / 100);
    }

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Purchases</h1>
                <p className="text-lg pb-2">
                    Get API credits and view your purchase history.
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">API Credits</h2>
                <p>All payments are processed through Stripe.</p>
                <p>1 credit costs $0.01.</p>
                <Form method="post" className="flex">
                    <input
                        type="number"
                        name="credit_amount"
                        placeholder="Amount of credits"
                        min="1"
                        max="99999999"
                        className="block p-2.5 grow max-w-sm bg-white focus:outline-none border rounded-l-md border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2.5 bg-green-600 hover:bg-green-700 rounded-r-md text-white font-semibold"
                    >
                        Purchase
                    </button>
                </Form>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">History</h2>
                <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
                    <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2 z-20">
                        <tr>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Date
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Type
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Amount
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Quantity
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Provider
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Invoice
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                        {purchaseData.purchases.map((purchase, index) => (
                            <tr
                                key={index}
                                className={`${
                                    index % 2 === 0
                                        ? 'bg-white dark:bg-gray-800'
                                        : 'bg-gray-50 dark:bg-gray-700'
                                }`}
                            >
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {formatDate(
                                        purchase.created_ts,
                                        'MMMM D, YYYY'
                                    )}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {purchase.type}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {formatPrice(purchase.amount)}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {purchase.quantity}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {purchase.provider}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    <a
                                        href={purchase.invoice_url}
                                        target="_blank"
                                        className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                                    >
                                        View
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Purchases;
