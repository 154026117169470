import { Link } from 'react-router-dom';
import { Check, ExternalLink, X } from 'react-feather';
import { formatDate } from '../../../utils';

interface Log {
    created_ts: number;
    price: number;
    status: string;
    reason: string;
    description: string;
}

interface Job {
    logs: Log[];
    results_uuid: string;
}

interface JobHistoryProps {
    job: Job;
    isAdmin: boolean;
}

function JobHistory({ job, isAdmin }: JobHistoryProps) {
    function getStatusIcon(status: string) {
        if (status === 'completed') {
            return (
                <span className="absolute flex items-center justify-center w-6 h-6 bg-green-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-800">
                    <Check className="text-black" />
                </span>
            );
        } else if (status === 'failed' || status === 'cancelled') {
            return (
                <span className="absolute flex items-center justify-center w-6 h-6 bg-red-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-800">
                    <X className="text-black" />
                </span>
            );
        } else {
            return (
                <span className="absolute flex items-center justify-center w-6 h-6 bg-yellow-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-800"></span>
            );
        }
    }

    function getStatusText(log: Log) {
        if (log.status === 'queued') {
            return 'Added to queue';
        } else if (log.status === 'cancelling') {
            return 'Cancellation request sent';
        } else if (log.status === 'running' && log.reason === 'remote cache') {
            return 'Accessed remote cache';
        } else if (log.status === 'running') {
            return 'Started running';
        } else {
            return log.status;
        }
    }

    function getLogDescription(log: Log) {
        if (log.price) {
            return `${log.price} credits consumed.`;
        } else if (log.status === 'running' && log.reason && log.description) {
            return `${log.description}.`;
        } else if (log.status === 'failed' && log.reason && log.description) {
            return `${log.reason}: ${log.description}.`;
        } else if (log.status === 'failed' && log.reason) {
            return `${log.reason}.`;
        }
    }

    return (
        <td colSpan={6} className="p-8">
            <h3 className="mb-5 text-lg font-semibold">Job History</h3>
            <ol className="relative border-s border-gray-200">
                {job.logs.map((log, index) => (
                    <li key={index} className="mb-10 ms-6">
                        {getStatusIcon(log.status)}
                        <p className="mb-1 first-letter:capitalize text-base font-semibold">
                            {getStatusText(log)}
                        </p>
                        <span className="timeline-timestamp block mb-2 text-sm font-normal leading-none text-gray-400">
                            {formatDate(
                                log.created_ts,
                                'MMMM D, YYYY, h:mm:ss A'
                            )}
                        </span>
                        <p className="first-letter:capitalize text-base font-normal">
                            {getLogDescription(log)}
                        </p>
                        {log.status === 'completed' && (
                            <Link
                                to={
                                    isAdmin
                                        ? `/admin/objects/${job.results_uuid}`
                                        : `/content/${job.results_uuid}`
                                }
                                target="_blank"
                                onClick={(event) => event.stopPropagation()}
                                className="inline-flex items-center mt-4 px-4 py-2 text-sm font-medium border border-gray-200 dark:border-gray-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300/10 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:focus:ring-gray-500"
                            >
                                <ExternalLink className="w-4 h-4 me-2" />
                                View results
                            </Link>
                        )}
                    </li>
                ))}
            </ol>
        </td>
    );
}

export default JobHistory;
