import React from 'react';
import { Form } from 'react-router-dom';
import { Search } from 'react-feather';
import FilterRow from './FilterRow';

interface WrapperProps {
    children: React.ReactNode;
    owners: { id: string; username: string; is_current_user: boolean }[];
    pageType: 'home' | 'explore';
}

function ObjectTableWrapper({ children, owners, pageType }: WrapperProps) {
    return (
        <>
            <Form className="w-full flex justify-center mb-4">
                <div className="relative w-4/5">
                    <input
                        name="search_query"
                        type="search"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                        placeholder="Search agents, questions, results..."
                        required
                    />
                    <button
                        type="submit"
                        aria-label="Search"
                        className="absolute top-0 end-0 px-4 py-2.5 text-sm font-medium h-full text-white bg-blue-500 hover:bg-blue-600 rounded-r-lg border-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                    >
                        <Search className="w-4 h-4" />
                    </button>
                </div>
            </Form>
            <FilterRow pageType={pageType} owners={owners} />
            {children}
        </>
    );
}

export default ObjectTableWrapper;
