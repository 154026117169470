import * as Popover from '@radix-ui/react-popover';
import { ChevronDown } from 'react-feather';

interface FilterState {
    objectType: string[];
    owners: string[];
    modified: string;
    visibility: string[];
    stars: string;
}

const filterBtnStyles =
    'text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-xs px-3 py-1.5 text-center inline-flex justify-center items-center dark:bg-gray-300 dark:hover:bg-gray-400 dark:focus:ring-gray-500';

function ObjectTypeDropdown({
    options,
    filters,
    handleChange,
}: {
    options: { id: number; name: string; value: string }[];
    filters: FilterState;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button type="button" className={filterBtnStyles}>
                    Type
                    <ChevronDown
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="max-h-[40vh] overflow-y-scroll w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ name, value, id }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`type-checkbox-${id}`}
                                    type="checkbox"
                                    checked={filters.objectType.includes(value)}
                                    onChange={handleChange}
                                    value={value}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`type-checkbox-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function OwnerDropdown({
    options,
    filters,
    handleChange,
}: {
    options: { id: string; username: string; is_current_user: boolean }[];
    filters: FilterState;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button type="button" className={filterBtnStyles}>
                    Owners
                    <ChevronDown
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-72 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ id, username, is_current_user }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`owner-checkbox-${id}`}
                                    type="checkbox"
                                    checked={filters.owners.includes(id)}
                                    onChange={handleChange}
                                    value={id}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`owner-checkbox-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {username}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function ModifiedDropdown({
    options,
    filters,
    handleChange,
}: {
    options: { id: number; name: string; value: string }[];
    filters: FilterState;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button type="button" className={filterBtnStyles}>
                    Modified
                    <ChevronDown
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ name, value, id }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`modified-radio-${id}`}
                                    type="radio"
                                    name="modified-radio"
                                    checked={filters.modified === value}
                                    onChange={handleChange}
                                    value={value}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`modified-radio-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function VisibilityDropdown({
    options,
    filters,
    handleChange,
}: {
    options: { id: number; name: string; value: string }[];
    filters: FilterState;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button type="button" className={filterBtnStyles}>
                    Visibility
                    <ChevronDown
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ name, value, id }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`visibility-checkbox-${id}`}
                                    type="checkbox"
                                    checked={filters.visibility.includes(value)}
                                    onChange={handleChange}
                                    value={value}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`visibility-checkbox-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function StarsDropdown({
    options,
    filters,
    handleChange,
}: {
    options: { id: number; name: string; value: string }[];
    filters: FilterState;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button type="button" className={filterBtnStyles}>
                    Stars
                    <ChevronDown
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ name, value, id }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`star-radio-${id}`}
                                    type="radio"
                                    name="star-radio"
                                    checked={filters.stars === value}
                                    onChange={handleChange}
                                    value={value}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`star-radio-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export {
    ModifiedDropdown,
    ObjectTypeDropdown,
    OwnerDropdown,
    StarsDropdown,
    VisibilityDropdown,
};
