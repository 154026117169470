import { useOutletContext } from 'react-router-dom';
import { ReactFlow, Background, Controls, MarkerType } from '@xyflow/react';
import '@xyflow/react/dist/style.css';

function SurveyFlow({
    questions,
    rule_collection,
}: {
    questions: {
        question_name: string;
        question_options?: string[];
        question_text: string;
        question_type: string;
    }[];
    rule_collection: {
        rules: {
            current_q: number;
            expression: string;
            next_q: number;
            priority: number;
        }[];
    };
}) {
    const isDarkMode: boolean = useOutletContext();

    const colors = {
        primaryEdge: isDarkMode ? '#d1d5db' : 'black',
        secondaryEdge: isDarkMode ? '#22d3ee' : 'blue',
    };

    const nodes = [];

    for (let [index, question] of questions.entries()) {
        nodes.push({
            id: index.toString(),
            position: { x: 0, y: index * 100 },
            data: {
                label: `${question.question_name}: ${question.question_text}`,
            },
        });
    }

    nodes.push({
        id: 'EndOfSurvey',
        position: { x: 0, y: nodes.length * 100 },
        data: { label: 'End of Survey' },
    });

    const edges = [];

    for (let i = 0; i < nodes.length - 2; i++) {
        edges.push({
            id: `${i}-${i + 1}`,
            source: `${i}`,
            target: `${i + 1}`,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: colors.primaryEdge,
            },
            style: {
                strokeWidth: 1.5,
                stroke: colors.primaryEdge,
            },
        });
    }

    edges.push({
        id: `${nodes.length - 2}-EndOfSurvey`,
        source: `${nodes.length - 2}`,
        target: 'EndOfSurvey',
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: colors.primaryEdge,
        },
        style: {
            strokeWidth: 1.5,
            stroke: colors.primaryEdge,
        },
    });

    const rulePriorityDefaultValue = -1;
    const relevantRules = rule_collection.rules.filter(
        (rule) => rule.priority > rulePriorityDefaultValue
    );

    relevantRules.forEach((rule) => {
        const source = rule.current_q;
        const target =
            rule.next_q === nodes.length - 1 ? 'EndOfSurvey' : rule.next_q;

        edges.push({
            id: `${source}-${target}`,
            source: `${source}`,
            target: `${target}`,
            label: `if ${rule.expression}`,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: colors.secondaryEdge,
            },
            style: {
                strokeWidth: 1.5,
                stroke: colors.secondaryEdge,
            },
        });

        nodes.forEach((node) => {
            const nodeId =
                node.id === 'EndOfSurvey'
                    ? nodes.length - 1
                    : parseInt(node.id);
            if (nodeId > rule.current_q && nodeId < rule.next_q) {
                node.position.x -= 150;
            }
        });
    });

    // console.log(nodes);
    // console.log(edges);

    return (
        <div className="h-[60vh]">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                colorMode={isDarkMode ? 'dark' : 'light'}
                fitView
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
}

export default SurveyFlow;
