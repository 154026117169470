import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Home, Power, Star, Tool, User } from 'react-feather';
import SidebarContainer from './SidebarContainer';
import { logout } from '../../helpers/auth.helpers';
import useAuth from '../../hooks/useAuth';

function WebAppSidebar() {
    const { isBetaUser } = useAuth();
    const navigate = useNavigate();

    function handleLogout() {
        logout().then((responseMsg: string) => {
            if (responseMsg === 'Logout successful!') {
                navigate('/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const activeNavLinkStyles =
        'text-blue-600 dark:text-primary-dark-text-accent font-bold';
    const inactiveNavLinkStyles =
        'rounded-md hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-blue-400 text-sm">
                <div className="-space-y-2 pb-2">
                    <NavLink
                        to="/home"
                        className={({ isActive }) =>
                            isActive
                                ? `w-full inline-flex items-center p-2 ${activeNavLinkStyles}`
                                : `w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`
                        }
                        end={true}
                    >
                        <Home
                            className="inline w-4 h-4 mr-1"
                            strokeWidth="1.5"
                        />
                        Home
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <Box className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Content
                    </div>
                    <NavLink
                        to="/content"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        My Content
                    </NavLink>
                    <NavLink
                        to="/explore"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Explore
                    </NavLink>
                    {isBetaUser && (
                        <NavLink
                            to="/create"
                            className={({ isActive }) =>
                                isActive
                                    ? `flex items-center p-2 ${activeNavLinkStyles}`
                                    : `flex items-center p-2 ${inactiveNavLinkStyles}`
                            }
                        >
                            Create
                            <Tool className="w-4 ml-1" strokeWidth="1.5" />
                        </NavLink>
                    )}
                    {isBetaUser && (
                        <NavLink
                            to="/home/projects"
                            className={({ isActive }) =>
                                isActive
                                    ? `flex p-2 ${activeNavLinkStyles}`
                                    : `flex p-2 ${inactiveNavLinkStyles}`
                            }
                        >
                            Projects
                            <Tool className="w-4 ml-1" strokeWidth="1.5" />
                        </NavLink>
                    )}
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <Star className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Services
                    </div>
                    <NavLink
                        to="/home/remote-cache"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Remote Cache
                    </NavLink>
                    <NavLink
                        to="/home/remote-inference"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Remote Inference
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <User className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Account
                    </div>
                    <NavLink
                        to="/home/api"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Coop API
                    </NavLink>
                    <NavLink
                        to="/home/purchases"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Purchases
                    </NavLink>
                    <NavLink
                        to="/home/profile"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Profile
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <button
                        onClick={handleLogout}
                        type="button"
                        className="w-full inline-flex items-center p-2 text-red-700 hover:text-red-900 hover:font-bold dark:text-amber-400 dark:hover:text-amber-500"
                    >
                        <Power
                            className="inline w-4 h-4 mr-1"
                            strokeWidth="2.5"
                        />
                        Log Out
                    </button>
                </div>
            </nav>
        </SidebarContainer>
    );
}

export default WebAppSidebar;
