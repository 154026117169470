import { Link, useOutletContext } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';
import EPLogo from './EPLogo';

function AppIndex() {
    const isDarkMode: boolean = useOutletContext();

    return (
        <ContentContainer additionalClasses="text-center">
            <div className="w-full flex flex-col items-center space-y-3 px-4">
                <EPLogo isDarkMode={isDarkMode} className="w-52 mt-10 mb-10" />
                <h1 className="text-7xl sm:text-6xl font-bold text-gray-500 dark:text-primary-dark-text drop-shadow-sm">
                    Expected Parrot
                </h1>
                <p className="text-4xl sm:text-4xl font-bold text-green-700 dark:text-primary-dark-text drop-shadow-sm pt-4 pb-12">
                    Open source tools for AI-powered research
                </p>
                <Link
                    to="/getting-started"
                    className="bg-green-700 hover:bg-green-800 border-2 border-green-700 hover:border-green-800 rounded-full px-10 py-2 text-xl sm:text-2xl text-white font-semibold"
                >
                    Get started
                </Link>
            </div>
        </ContentContainer>
    );
}

export default AppIndex;
