import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'update_username') {
        const username = formData.get('username');
        response = await fetch('/api/update-username', {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username }),
        });
    } else if (intent === 'toggle_beta') {
        response = await fetch('/api/update-beta-status', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                value: formData.get('value'),
            }),
        });
    } else if (intent === 'delete_account') {
        response = await fetch('/api/home/delete-account', {
            method: 'DELETE',
        });
    }

    if (response.ok && intent === 'delete_account') {
        return redirect('/goodbye');
    } else if (response.ok) {
        const successData = await response.json();
        return successData.message;
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
