import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Filter } from 'react-feather';

const options = [
    {
        id: 1,
        name: 'Queued',
        value: 'queued',
    },
    {
        id: 2,
        name: 'Running',
        value: 'running',
    },
    {
        id: 3,
        name: 'Cancelling',
        value: 'cancelling',
    },
    {
        id: 4,
        name: 'Cancelled',
        value: 'cancelled',
    },
    {
        id: 5,
        name: 'Failed',
        value: 'failed',
    },
    {
        id: 6,
        name: 'Completed',
        value: 'completed',
    },
];

function StatusDropdown() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [statusFilters, setStatusFilters] = useState(() => {
        return searchParams.getAll('status');
    });

    useEffect(() => {
        setStatusFilters(searchParams.getAll('status'));
    }, [searchParams]);

    function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
        const prevFilters = [...statusFilters];
        const nextFilters = event.target.checked
            ? // If checkbox is checked, add value to list
              [...prevFilters, event.target.value]
            : // Otherwise, remove value from list
              prevFilters.filter(
                  (objectType) => objectType !== event.target.value
              );
        setStatusFilters(nextFilters);
    }

    function getFilterURL() {
        const searchParams = new URLSearchParams(window.location.search);

        const paramsToReset = ['page', 'status'];
        for (const param of paramsToReset) {
            searchParams.delete(param);
        }

        // Set new params
        for (const value of statusFilters) {
            searchParams.append('status', value);
        }

        return `?${searchParams.toString()}`;
    }

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button
                    type="button"
                    className="inline-flex items-center mt-4 px-4 py-2 gap-x-2 text-sm font-medium border border-gray-200 dark:border-gray-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300/10 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:focus:ring-gray-500"
                >
                    <Filter className="inline w-4 h-4" strokeWidth="2" />
                    Status
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="max-h-[40vh] overflow-y-scroll w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                        {options.map(({ name, value, id }) => (
                            <li
                                key={id}
                                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                            >
                                <input
                                    id={`status-checkbox-${id}`}
                                    type="checkbox"
                                    checked={statusFilters.includes(value)}
                                    onChange={handleStatusChange}
                                    value={value}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                    htmlFor={`status-checkbox-${id}`}
                                    className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                    {name}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <Popover.Close asChild>
                        <Link
                            to={getFilterURL()}
                            className="flex justify-center items-center p-3 text-sm font-medium text-blue-600 dark:text-primary-dark-text border-t border-gray-200 dark:border-primary-dark-border rounded-b-lg hover:underline"
                        >
                            Apply
                        </Link>
                    </Popover.Close>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default StatusDropdown;
