import { Globe, Linkedin, Mail } from 'react-feather';
import ContentContainer from '../base/ContentContainer';

interface CofounderDetails {
    id: number;
    name: string;
    imgSrc: string;
    imgAlt: string;
    email?: string;
    linkedIn?: string;
    personalSite?: string;
}

function About() {
    const robinPhotoURL = new URL(
        '../../../public/assets/images/robin.jpg',
        import.meta.url
    ).pathname;
    const johnPhotoURL = new URL(
        '../../../public/assets/images/john.jpeg',
        import.meta.url
    ).pathname;
    const apostolosPhotoURL = new URL(
        '../../../public/assets/images/apostolos.png',
        import.meta.url
    ).pathname;

    const cofounders: CofounderDetails[] = [
        {
            id: 1,
            name: 'Robin Horton',
            imgSrc: robinPhotoURL,
            imgAlt: 'Robin Horton. Our amazing CEO!',
            email: 'mailto: robin@expectedparrot.com',
            linkedIn: 'https://www.linkedin.com/in/robertahorton/',
            personalSite: null,
        },
        {
            id: 2,
            name: 'John Horton',
            imgSrc: johnPhotoURL,
            imgAlt: "John Horton. Well, he's certainly the oldest cofounder",
            email: 'mailto: john@expectedparrot.com',
            linkedIn: 'https://www.linkedin.com/in/john-horton-48a75819/',
            personalSite: 'https://john-joseph-horton.com/',
        },
        {
            id: 3,
            name: 'Apostolos Filippas',
            imgSrc: apostolosPhotoURL,
            imgAlt: 'Apostolos Filippas. Who invited this guy?!',
            email: 'mailto: apostolos@expectedparrot.com',
            linkedIn: 'https://www.linkedin.com/in/apostolos-filippas-8a1374b8',
            personalSite: 'https://apostolos-filippas.com',
        },
    ];

    return (
        <ContentContainer additionalClasses="text-center">
            <div className="max-w-4xl mx-auto mt-16">
                <h1 className="text-3xl sm:text-5xl font-bold leading-[1.25]">
                    Building tools for AI-powered research.
                </h1>
                <p className="text-xl mt-12">
                    Our work draws on the{' '}
                    <a
                        href="https://arxiv.org/abs/2404.11794"
                        target="_blank"
                        className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                    >
                        growing
                    </a>{' '}
                    body of{' '}
                    <a
                        href="https://arxiv.org/abs/2301.07543"
                        target="_blank"
                        className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                    >
                        research
                    </a>{' '}
                    demonstrating that AI can be used to simulate surveys,
                    experiments and other empirical studies. We believe that
                    this breakthrough has far-ranging applications for user and
                    market research; our goal is to make conducting credible
                    AI-powered research easy.
                </p>
            </div>
            <div className="w-full mt-8 px-5 sm:px-16">
                <div className="max-w-3xl mx-auto">
                    <p className="text-xl mt-4">
                        We are based in Cambridge, Massachusetts and we are
                        backed by{' '}
                        <a
                            href="https://www.bloombergbeta.com/"
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                            target="_blank"
                        >
                            Bloomberg Beta
                        </a>
                        . Interested in knowing more or working with us? Email
                        us at{' '}
                        <a
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                            href="mailto:info@expectedparrot.com"
                        >
                            info@expectedparrot.com
                        </a>
                        .
                    </p>
                </div>
                <div className="w-full flex flex-wrap justify-center mt-16">
                    {cofounders.map((cofounder) => (
                        <div
                            key={cofounder.id}
                            className="flex flex-col w-full md:w-1/3 space-y-4 items-center mb-10 md:mb-0 px-4"
                        >
                            <div className="w-56 h-56 rounded-full overflow-hidden shadow-lg">
                                <img
                                    src={cofounder.imgSrc}
                                    alt={cofounder.imgAlt}
                                    className="object-cover rounded-full h-full w-full"
                                />
                            </div>
                            <h2 className="text-2xl font-bold text-center">
                                {cofounder.name}
                            </h2>
                            <div className="flex justify-center space-x-6">
                                {cofounder.email && (
                                    <a
                                        href={cofounder.email}
                                        aria-label="Send email"
                                        className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                                    >
                                        <Mail
                                            strokeWidth="1.5"
                                            aria-hidden="true"
                                        />
                                    </a>
                                )}
                                {cofounder.linkedIn && (
                                    <a
                                        href={cofounder.linkedIn}
                                        target="_blank"
                                        aria-label="Open LinkedIn profile"
                                        className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                                    >
                                        <Linkedin
                                            strokeWidth="1.5"
                                            aria-hidden="true"
                                        />
                                    </a>
                                )}
                                {cofounder.personalSite && (
                                    <a
                                        href={cofounder.personalSite}
                                        target="_blank"
                                        aria-label="Go to personal site"
                                        className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                                    >
                                        <Globe
                                            strokeWidth="1.5"
                                            aria-hidden="true"
                                        />
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ContentContainer>
    );
}

export default About;
