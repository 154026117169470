import { useFetcher } from 'react-router-dom';

function UpdateCreditsForm({ email }: { email: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="email" value={email} />
            <select name="increment" className="border border-gray-500 mr-2">
                {Array.from({ length: 11 }, (_, i) => -500 + i * 100).map(
                    (value) => (
                        <option key={value} value={-value}>
                            {-value}
                        </option>
                    )
                )}
            </select>
            <button
                name="intent"
                value="update_credits"
                type="submit"
                className="py-1 px-3 bg-blue-500 text-white rounded"
            >
                Update
            </button>
        </fetcher.Form>
    );
}

export default UpdateCreditsForm;
