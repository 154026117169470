import { Edit, UserPlus, Trash2 } from 'react-feather';
import {
    ChangeVisibilityBtn,
    CopyLinkToContentBtn,
    DownloadBtn,
    DeleteModal,
    EditModal,
    FavoriteBtn,
    ShareModal,
} from './ActionRowButtons';
import { EDSLObject } from '../../../../types';

function ActionRow({
    object,
    activeTab,
    children,
}: {
    object: EDSLObject;
    activeTab: string;
    children: React.ReactNode;
}) {
    const btnBaseStyles =
        'py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700';

    if (!object.is_editable_by_user) {
        return (
            <div className="flex flex-wrap justify-between p-4 border border-gray-200 bg-gray-50 rounded-t-xl dark:border-gray-600 dark:bg-gray-700">
                <div className="flex items-center">
                    {children}
                    <span className="ml-4 font-medium font-mono">
                        {object.data.filesize}
                    </span>
                </div>
                <div className="flex">
                    <div className="flex flex-wrap">
                        {!object.is_stale && (
                            <DownloadBtn
                                objectId={object.id}
                                baseStyles={btnBaseStyles}
                            />
                        )}
                    </div>
                    <div className="inline-flex me-2" role="group">
                        {/* Favorite button */}
                        <FavoriteBtn
                            objectId={object.id}
                            isStarredByUser={object.is_starred_by_user}
                            baseStyles={btnBaseStyles}
                        />
                        {/* Copy link to content button */}
                        <CopyLinkToContentBtn
                            objectId={object.id}
                            baseStyles={`${btnBaseStyles} border-r rounded-e-lg`}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-wrap justify-between p-4 border border-gray-200 bg-gray-50 rounded-t-xl dark:border-gray-600 dark:bg-gray-700">
            <div className="flex items-center">
                {children}
                <span className="ml-4 text-sm text-gray-600 dark:text-gray-300 font-medium font-mono">
                    {object.data.filesize}
                </span>
            </div>
            <div className="flex flex-wrap">
                {!object.is_stale && (
                    <DownloadBtn
                        objectId={object.id}
                        baseStyles={btnBaseStyles}
                    />
                )}
                <div className="inline-flex me-2" role="group">
                    {/* Edit description button */}
                    <EditModal
                        objectId={object.id}
                        description={object.description}
                    >
                        <button
                            type="button"
                            className={`flex items-center border-t border-b border-l rounded-s-lg hover:text-blue-700 dark:hover:text-white ${btnBaseStyles}`}
                        >
                            <Edit className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </EditModal>
                    {/* Delete button */}
                    <DeleteModal objectId={object.id}>
                        <button
                            type="button"
                            className={`flex items-center border rounded-e-lg hover:text-red-500 dark:hover:text-white ${btnBaseStyles}`}
                        >
                            <Trash2
                                className="inline w-4 h-4"
                                strokeWidth="2"
                            />
                        </button>
                    </DeleteModal>
                </div>
                <div className="inline-flex me-2" role="group">
                    {/* Favorite button */}
                    <FavoriteBtn
                        objectId={object.id}
                        isStarredByUser={object.is_starred_by_user}
                        baseStyles={btnBaseStyles}
                    />
                    {/* Copy link to content button */}
                    <CopyLinkToContentBtn
                        objectId={object.id}
                        baseStyles={btnBaseStyles}
                    />
                    {/* Share button */}
                    <ShareModal
                        objectId={object.id}
                        isOwnedByUser={object.is_owned_by_user}
                        sharedWith={object.shared_with}
                    >
                        <button
                            type="button"
                            className={`flex items-center rounded-e-lg border hover:text-blue-700 dark:hover:text-white ${btnBaseStyles}`}
                        >
                            <UserPlus
                                className="inline w-4 h-4"
                                strokeWidth="2"
                            />
                        </button>
                    </ShareModal>
                </div>
                {/* Change visibility button */}
                <ChangeVisibilityBtn
                    objectId={object.id}
                    visibility={object.visibility}
                    baseStyles={btnBaseStyles}
                />
            </div>
        </div>
    );
}

export default ActionRow;
