import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
    modifiedOptions,
    objectTypeOptions,
    starOptions,
    visibilityOptions as allVisibilityOptions,
} from './filterOptions';
import {
    ModifiedDropdown,
    ObjectTypeDropdown,
    OwnerDropdown,
    StarsDropdown,
    VisibilityDropdown,
} from './FilterDropdowns';

function FilterRow({
    pageType,
    owners,
}: {
    pageType: 'home' | 'explore';
    owners: { id: string; username: string; is_current_user: boolean }[];
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState(() => {
        return {
            objectType: searchParams.getAll('type'),
            owners: searchParams.getAll('owner'),
            modified: searchParams.get('modified') || 'all_time',
            visibility: searchParams.getAll('visibility'),
            stars: searchParams.get('stars') || 'all_content',
        };
    });

    useEffect(() => {
        setFilters({
            objectType: searchParams.getAll('type'),
            owners: searchParams.getAll('owner'),
            modified: searchParams.get('modified') || 'all_time',
            visibility: searchParams.getAll('visibility'),
            stars: searchParams.get('stars') || 'all_content',
        });
    }, [searchParams]);

    function handleModifiedChange(event: React.ChangeEvent<HTMLInputElement>) {
        const prevFilters = { ...filters };
        setFilters({ ...prevFilters, modified: event.target.value });
    }

    function handleObjectTypeChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const prevFilters = { ...filters };
        const nextObjectType = event.target.checked
            ? // If checkbox is checked, add value to list
              [...prevFilters.objectType, event.target.value]
            : // Otherwise, remove value from list
              prevFilters.objectType.filter(
                  (objectType) => objectType !== event.target.value
              );
        setFilters({
            ...prevFilters,
            objectType: nextObjectType,
        });
    }

    function handleOwnersChange(event: React.ChangeEvent<HTMLInputElement>) {
        const prevFilters = { ...filters };
        const nextOwners = event.target.checked
            ? // If checkbox is checked, add value to list
              [...prevFilters.owners, event.target.value]
            : // Otherwise, remove value from list
              prevFilters.owners.filter(
                  (owners) => owners !== event.target.value
              );
        setFilters({
            ...prevFilters,
            owners: nextOwners,
        });
    }

    function handleStarsChange(event: React.ChangeEvent<HTMLInputElement>) {
        const prevFilters = { ...filters };
        setFilters({ ...prevFilters, stars: event.target.value });
    }

    function handleVisibilityChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const prevFilters = { ...filters };
        const nextVisibility = event.target.checked
            ? // If checkbox is checked, add value to list
              [...prevFilters.visibility, event.target.value]
            : // Otherwise, remove value from list
              prevFilters.visibility.filter(
                  (visibility) => visibility !== event.target.value
              );
        setFilters({
            ...prevFilters,
            visibility: nextVisibility,
        });
    }

    function getFilterURL() {
        const searchParams = new URLSearchParams(window.location.search);

        const paramsToReset = [
            'page',
            'type',
            'owner',
            'modified',
            'visibility',
            'stars',
            'search_query',
            'sort_col',
            'sort_ascending',
        ];
        for (const param of paramsToReset) {
            searchParams.delete(param);
        }

        // Set new params
        for (const value of filters.objectType) {
            searchParams.append('type', value);
        }
        for (const value of filters.owners) {
            searchParams.append('owner', value);
        }
        for (const value of filters.visibility) {
            searchParams.append('visibility', value);
        }
        if (filters.modified !== 'all_time') {
            searchParams.set('modified', filters.modified);
        }
        if (filters.stars !== 'all_content') {
            searchParams.set('stars', filters.stars);
        }

        return `?${searchParams.toString()}`;
    }

    const ownerOptions = owners
        .sort((a, b) => {
            if (a.is_current_user) return -1;
            if (b.is_current_user) return 1;
            return a.username.localeCompare(b.username);
        })
        .map((owner) => ({
            ...owner,
            username: owner.is_current_user
                ? `${owner.username} (You)`
                : owner.username,
        }));

    const visibilityOptions =
        pageType === 'home'
            ? allVisibilityOptions
            : allVisibilityOptions.filter(
                  (visibility) => visibility.name === 'public'
              );

    return (
        <div className="flex flex-wrap justify-center space-x-4 mb-4">
            {/* Goes either content or explore */}
            <Link
                to={window.location.pathname}
                className="text-gray-900 focus:outline-none bg-white border border-gray-400 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 font-medium rounded-full text-xs px-3 py-1.5 text-center inline-flex items-center"
            >
                Clear all
            </Link>
            <ObjectTypeDropdown
                options={objectTypeOptions}
                filters={filters}
                handleChange={handleObjectTypeChange}
            />
            <OwnerDropdown
                options={ownerOptions}
                filters={filters}
                handleChange={handleOwnersChange}
            />
            <ModifiedDropdown
                options={modifiedOptions}
                filters={filters}
                handleChange={handleModifiedChange}
            />
            <VisibilityDropdown
                options={visibilityOptions}
                filters={filters}
                handleChange={handleVisibilityChange}
            />
            <StarsDropdown
                options={starOptions}
                filters={filters}
                handleChange={handleStarsChange}
            />
            <Link
                to={getFilterURL()}
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-xs px-3 py-1.5 text-center inline-flex items-center"
            >
                Filter
            </Link>
        </div>
    );
}

export default FilterRow;
