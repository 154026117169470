import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';
import Callout from '../../Callout';
import CodeBlock from '../../CodeBlock';
import InlineCode from '../../InlineCode';

function EDSLInstallation() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Installing EDSL</h1>
                <p>
                    This page provides instructions for installing the EDSL
                    library on your system.
                </p>
                <p>
                    <span className="italic">Requirements:</span>{' '}
                    <a
                        href="https://www.python.org/downloads/"
                        target="_blank"
                        className={linkStyles}
                    >
                        Python
                    </a>{' '}
                    3.9 and above
                </p>
                <br />
                <Callout title="Tip">
                    View this page in the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/installation.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        EDSL Docs
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    where you can also find troubleshooting tips, starter
                    tutorials and in-depth documentation.
                </Callout>
            </div>
            <div className="space-y-2">
                <h1 className="font-bold text-2xl">Quickstart Installation</h1>
                <p>
                    Open your terminal and verify that you have not previously
                    installed EDSL on your system:
                </p>
                <CodeBlock>pip show edsl</CodeBlock>
                <p>To install the latest version of EDSL through pip:</p>
                <CodeBlock>pip install edsl</CodeBlock>
                <p>To update to the latest version of EDSL:</p>
                <CodeBlock>pip install --upgrade edsl</CodeBlock>
            </div>
            <div className="space-y-2">
                <h1 className="font-bold text-2xl">Advanced Installation</h1>
                <p>
                    The Quickstart Installation steps will install EDSL globally
                    on your system. Sometimes, you may face problems with
                    conflicts between EDSL and other libraries. To avoid such
                    problems, we recommend that you use a virtual environment.
                </p>
                <p>Open your terminal and run the following command:</p>
                <CodeBlock>python3 -m venv myenv</CodeBlock>
                <p>
                    This will create a folder called{' '}
                    <InlineCode>myenv</InlineCode>. Next, activate your virtual
                    environment:
                </p>
                <p> MacOS and Linux:</p>
                <CodeBlock>source myenv/bin/activate</CodeBlock>
                <p> Windows:</p>
                <CodeBlock>myenv\Scripts\activate</CodeBlock>
                <p>
                    You can now install EDSL through pip within your virtual
                    environment:
                </p>
                <CodeBlock>pip install edsl</CodeBlock>
                <p>
                    You will have access to EDSL while your virtual environment
                    is activated.
                </p>
                <p>
                    You can deactivate the virtual environment at any time by
                    running:
                </p>
                <CodeBlock>deactivate</CodeBlock>
                <p>
                    To delete the virtual environment, simply delete the{' '}
                    <InlineCode>myenv</InlineCode> folder.
                </p>
            </div>
            <div>
                <p>
                    Next:{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        Storing your API keys for LLMs
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
        </>
    );
}

export default EDSLInstallation;
