import * as Dialog from '@radix-ui/react-dialog';
import * as Popover from '@radix-ui/react-popover';
import { useFetcher } from 'react-router-dom';
import {
    Check,
    Copy,
    Download,
    EyeOff,
    Link,
    Lock,
    Star,
    Users,
    X,
} from 'react-feather';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

function VisibilityItem({
    objectId,
    name,
    value,
}: {
    objectId: string;
    name: string;
    value: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="visibility" value={value} />
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                className="w-24 select-none outline-none rounded-sm pl-4 pr-8 py-1 hover:bg-sky-600 hover:text-white"
                name="intent"
                value="change_visibility"
                type="submit"
            >
                {name}
            </button>
        </fetcher.Form>
    );
}

function ChangeVisibilityBtn({
    objectId,
    visibility,
    baseStyles,
}: {
    objectId: string;
    visibility: string;
    baseStyles: string;
}) {
    if (visibility === 'public') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-green-600 hover:text-green-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <Users
                            className="inline w-4 h-4 mr-2"
                            strokeWidth="2"
                        />
                        Public
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Private"
                                value="private"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Unlisted"
                                value="unlisted"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    } else if (visibility === 'private') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-red-600 hover:text-red-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <Lock className="inline w-4 h-4 mr-2" strokeWidth="2" />
                        Private
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Public"
                                value="public"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Unlisted"
                                value="unlisted"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    } else if (visibility === 'unlisted') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-orange-600 hover:text-orange-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <EyeOff
                            className="inline w-4 h-4 mr-2"
                            strokeWidth="2"
                        />
                        Unlisted
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Private"
                                value="private"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Public"
                                value="public"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    }
}

function CopyCodeBtn({
    code,
    baseStyles,
}: {
    code: string;
    baseStyles: string;
}) {
    const [isCopied, copy] = useCopyToClipboard();

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <button
            type="button"
            onClick={() => handleCopy(code)}
            className={`flex items-center border rounded-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
        >
            {isCopied ? (
                <Check className="inline w-4 h-4" strokeWidth="2" />
            ) : (
                <Copy className="inline w-4 h-4" strokeWidth="2" />
            )}
        </button>
    );
}

function CopyLinkToContentBtn({
    objectId,
    baseStyles,
}: {
    objectId: string;
    baseStyles: string;
}) {
    const [isCopied, copy] = useCopyToClipboard();

    const rootUrl = window.location.origin;
    const linkToContent = `${rootUrl}/content/${objectId}`;

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <button
            type="button"
            onClick={() => handleCopy(linkToContent)}
            className={`flex items-center border-t border-b hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
        >
            {isCopied ? (
                <Check className="inline w-4 h-4" strokeWidth="2" />
            ) : (
                <Link className="inline w-4 h-4" strokeWidth="2" />
            )}
        </button>
    );
}

function DownloadBtn({
    objectId,
    baseStyles,
}: {
    objectId: string;
    baseStyles: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form className="flex" method="post">
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="download"
                className={`flex items-center me-2 border rounded-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
            >
                <Download className="inline w-4 h-4" strokeWidth="2" />
            </button>
        </fetcher.Form>
    );
}

function UnshareBtn({ objectId, email }: { objectId: string; email: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="unshare"
                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            >
                Remove
            </button>
        </fetcher.Form>
    );
}

function DeleteModal({
    objectId,
    children,
}: {
    objectId: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    function getDeleteFeedback() {
        if (!fetcher.data) {
            return;
        }
        return (
            <p className="text-sm text-red-600 dark:text-red-500">
                {fetcher.data}
            </p>
        );
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <div className="flex flex-col items-center text-sm text-center py-4 md:py-5">
                        <Dialog.Title asChild>
                            <h3 className="mb-5 text-lg font-normal">
                                Are you sure you want to delete this object?
                            </h3>
                        </Dialog.Title>
                        <div className="flex">
                            <Dialog.Close asChild>
                                <button
                                    type="button"
                                    className="py-2.5 px-5 me-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    No, cancel
                                </button>
                            </Dialog.Close>
                            <fetcher.Form method="post">
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                                <button
                                    type="submit"
                                    name="intent"
                                    value="delete"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    Yes, I'm sure
                                </button>
                            </fetcher.Form>
                        </div>
                        {getDeleteFeedback()}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function EditModal({
    objectId,
    description,
    children,
}: {
    objectId: string;
    description: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    function getEditFeedback() {
        if (!fetcher.data) {
            return;
        }
        if (fetcher.data.includes('Successfully updated')) {
            return (
                <p className="text-sm text-green-600 dark:text-green-500">
                    {fetcher.data}
                </p>
            );
        } else {
            return (
                <p className="text-sm text-red-600 dark:text-red-500">
                    {fetcher.data}
                </p>
            );
        }
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor={`edit-description-input-${objectId}`}
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Description
                                </label>
                                <textarea
                                    id={`edit-description-input-${objectId}`}
                                    rows={4}
                                    name="description"
                                    className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue={description}
                                />
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="edit"
                                className="w-full mt-3 mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Save
                            </button>
                        </fetcher.Form>
                        {getEditFeedback()}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function ShareModal({
    objectId,
    isOwnedByUser,
    sharedWith,
    children,
}: {
    objectId: string;
    isOwnedByUser: boolean;
    sharedWith: { username: string; email: string }[];
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    function getShareFeedback() {
        if (!fetcher.data) {
            return;
        }
        if (fetcher.data.includes('Successfully shared with')) {
            return (
                <p className="text-sm text-green-600 dark:text-green-500">
                    {fetcher.data}
                </p>
            );
        } else {
            return (
                <p className="text-sm text-red-600 dark:text-red-500">
                    {fetcher.data}
                </p>
            );
        }
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Share
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor={`share-email-input-${objectId}`}
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Add user by email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id={`share-email-input-${objectId}`}
                                    className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    placeholder="name@company.com"
                                    required
                                />
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="share"
                                className="w-full mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Share
                            </button>
                        </fetcher.Form>
                        {getShareFeedback()}
                        {/* Don't show sharing details if the current user is not the object owner */}
                        {isOwnedByUser && (
                            <>
                                <p className="block mt-4 mb-2 text-base font-medium text-gray-900 dark:text-white">
                                    People with access
                                </p>
                                <ul>
                                    {sharedWith.map((user, index) => (
                                        <li key={index}>
                                            <div className="flex justify-between border-t pt-2 border-gray-200 dark:border-gray-600">
                                                <div className="font-medium">
                                                    {user.username}
                                                    <div className="font-normal text-gray-500 dark:text-gray-300">
                                                        {user.email}
                                                    </div>
                                                </div>
                                                {user.username.includes(
                                                    '(You)'
                                                ) ? (
                                                    <div className="text-gray-900 bg-white font-medium rounded-lg text-sm px-3 py-2 me-2 mb-2">
                                                        Owner
                                                    </div>
                                                ) : (
                                                    <UnshareBtn
                                                        objectId={objectId}
                                                        email={user.email}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function FavoriteBtn({
    objectId,
    isStarredByUser,
    baseStyles,
}: {
    objectId: string;
    isStarredByUser: boolean;
    baseStyles: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post" className="flex">
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="toggle_star"
                className={`flex items-center border rounded-s-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
            >
                <Star
                    className={`inline w-4 h-4 ${
                        isStarredByUser && 'fill-black'
                    }`}
                    strokeWidth="2"
                />
            </button>
        </fetcher.Form>
    );
}

export {
    ChangeVisibilityBtn,
    CopyCodeBtn,
    CopyLinkToContentBtn,
    DeleteModal,
    DownloadBtn,
    EditModal,
    FavoriteBtn,
    ShareModal,
};
