import questionSchema from './questionSchema';
import {
    Question as EDSLQuestion,
    QuestionType,
    BudgetQuestion,
    CheckBoxQuestion,
    FreeTextQuestion,
    LikertFiveQuestion,
    LinearScaleQuestion,
    ListQuestion,
    MultipleChoiceQuestion,
    NumericalQuestion,
    RankQuestion,
    TopKQuestion,
    YesNoQuestion,
} from './types';

function addIdsToOptions(defaultOptions: { text: string }[]) {
    const optionsWithIds = defaultOptions.map((option) => {
        return {
            id: crypto.randomUUID(),
            text: option.text,
        };
    });
    return optionsWithIds;
}

function addIdsToLabeledOptions(
    defaultOptions: { text: string; label: string }[]
) {
    const optionsWithIds = defaultOptions.map((option) => {
        return {
            id: crypto.randomUUID(),
            text: option.text,
            label: option.label,
        };
    });
    return optionsWithIds;
}

function getNewQuestion(type: QuestionType): EDSLQuestion {
    const baseQuestion = {
        id: crypto.randomUUID(),
        name: '',
        text: '',
    };

    switch (type) {
        case 'budget': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: BudgetQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'checkbox': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: CheckBoxQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'free_text': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: FreeTextQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'likert_five': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: LikertFiveQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'linear_scale': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: LinearScaleQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToLabeledOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'list': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: ListQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'multiple_choice': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: MultipleChoiceQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'numerical': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: NumericalQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'rank': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: RankQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'top_k': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: TopKQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'yes_no': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: YesNoQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
    }
}

function getUpdatedQuestion(
    question: EDSLQuestion,
    newType: QuestionType
): EDSLQuestion {
    switch (newType) {
        case 'budget': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'checkbox': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'free_text': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'likert_five': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'linear_scale': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToLabeledOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'list': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'multiple_choice': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'numerical': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'rank': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'top_k': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'yes_no': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
        }
    }
}

export { getNewQuestion, getUpdatedQuestion };
