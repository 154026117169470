import { NavLink } from 'react-router-dom';
import { ExternalLink, Home } from 'react-feather';
import SidebarContainer from '../base/SidebarContainer';

function Sidebar() {
    const activeNavLinkStyles =
        'text-blue-600 dark:text-primary-dark-text-accent font-bold';
    const inactiveNavLinkStyles =
        'hover:bg-gray-200 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-blue-400 text-sm">
                <div className="-space-y-2 pb-2">
                    <NavLink
                        to="/getting-started"
                        className={({ isActive }) =>
                            isActive
                                ? `w-full inline-flex items-center p-2 ${activeNavLinkStyles}`
                                : `w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`
                        }
                        end={true}
                    >
                        <Home
                            className="inline w-4 h-4 mr-1"
                            strokeWidth="1.5"
                        />
                        Home
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <p className="block p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        EDSL
                    </p>
                    <NavLink
                        to="/getting-started/edsl-installation"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Installing EDSL
                    </NavLink>
                    <NavLink
                        to="/getting-started/edsl-api-keys"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        API Keys for LLMs
                    </NavLink>
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        className={`w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`}
                    >
                        Starter Tutorial{' '}
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>
                </div>
                <div className="-space-y-2">
                    <p className="block p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        Coop
                    </p>
                    <NavLink
                        to="/getting-started/coop-how-it-works"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        How it works
                    </NavLink>
                    <NavLink
                        to="/getting-started/coop-hosted-simulations"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Hosted simulations
                    </NavLink>
                </div>
            </nav>
        </SidebarContainer>
    );
}

export default Sidebar;
