import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Menu, Power, X } from 'react-feather';
import DarkModeToggle from './DarkModeToggle';
import EPLogo from './EPLogo';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../helpers/auth.helpers';

interface HeaderProps {
    isDarkMode: boolean;
    toggleDarkMode: (value: boolean) => void;
}

function Header({ isDarkMode, toggleDarkMode }: HeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    // Unmount hamburger menu at medium breakpoints
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768 && isMenuOpen) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMenuOpen]);

    // Restore body scroll when hamburger menu is unmounted
    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    function handleLogout() {
        toggleMenu();
        logout().then((responseMsg: string) => {
            if (responseMsg === 'Logout successful!') {
                navigate('/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const baseNavItemStyles = 'flex items-center ml-3 px-3 py-2 font-semibold';
    const activeNavItemStyles = `underline underline-offset-8 text-green-700 dark:text-primary-dark-text-accent`;
    const inactiveNavItemStyles =
        'text-gray-500 hover:text-green-700 dark:text-primary-dark-text dark:hover:text-primary-dark-text-accent';

    // For items that don't need to differentiate between active and inactive states (e.g., Discord link)
    const standardNavItemStyles = `${baseNavItemStyles} ${inactiveNavItemStyles}`;

    return (
        <div
            className={
                isMenuOpen
                    ? 'h-screen flex flex-col sticky top-0 z-40'
                    : 'w-100 shrink-0'
            }
        >
            <header className="h-16 shrink-0 flex justify-between items-center bg-white border-b border-gray-300 px-5 dark:bg-primary-dark-bg dark:border-slate-700">
                <div className="flex items-center">
                    {/* Hamburger menu */}
                    <button
                        onClick={toggleMenu}
                        type="button"
                        className={`block md:hidden ${standardNavItemStyles}`}
                        aria-label="Navigation"
                    >
                        {isMenuOpen ? <X /> : <Menu />}
                    </button>
                    <Link to="/" className={standardNavItemStyles}>
                        <EPLogo isDarkMode={isDarkMode} className="w-14 mr-3" />
                        <span className="hidden lg:block">Expected Parrot</span>
                    </Link>
                </div>
                <div className="flex items-center">
                    <DarkModeToggle
                        isDarkMode={isDarkMode}
                        toggleDarkMode={toggleDarkMode}
                        styles={standardNavItemStyles}
                    />
                    <nav className="hidden md:flex md:flex-row gap-2">
                        <NavLink
                            to="/getting-started"
                            className={({ isActive }) =>
                                isActive
                                    ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                    : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                            }
                        >
                            Get started
                        </NavLink>
                        <a
                            href="https://docs.expectedparrot.com/en/latest/index.html"
                            target="_blank"
                            className={standardNavItemStyles}
                        >
                            Docs
                        </a>
                        <a
                            href="https://discord.com/invite/mxAYkjfy9m"
                            target="_blank"
                            className={standardNavItemStyles}
                        >
                            Discord
                        </a>
                        <NavLink
                            to="/about"
                            className={({ isActive }) =>
                                isActive
                                    ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                    : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                            }
                        >
                            About
                        </NavLink>
                        <NavLink
                            to={isLoggedIn === true ? '/home' : '/login'}
                            className="bg-green-600 hover:bg-green-700 ml-4 border-2 border-green-600 hover:border-green-700 rounded-md px-3 py-2 text-white font-semibold"
                        >
                            {isLoggedIn === true ? 'Coop' : 'Login'}
                        </NavLink>
                    </nav>
                </div>
            </header>
            {/* Menu for smaller screens */}
            {isMenuOpen && (
                <div className="overflow-y-auto isolate grow bg-white px-11 py-5 dark:bg-primary-dark-bg dark:border-slate-700 dark:text-primary-dark-text">
                    <nav className="flex flex-col gap-5">
                        <NavLink
                            to={isLoggedIn === true ? '/home' : '/login'}
                            onClick={toggleMenu}
                            className="bg-green-700 hover:bg-green-800 border-2 border-green-700 hover:border-green-800 rounded-md ml-2 px-3 py-2 text-white font-semibold"
                        >
                            {isLoggedIn === true ? 'Coop' : 'Login'}
                        </NavLink>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                GET STARTED
                            </p>
                            <NavLink
                                to="/getting-started"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/getting-started/edsl-installation"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Installing EDSL
                            </NavLink>
                            <NavLink
                                to="/getting-started/edsl-api-keys"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                API Keys for LLMs
                            </NavLink>
                            <a
                                href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Starter Tutorial
                            </a>
                            <NavLink
                                to="/getting-started/coop-how-it-works"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                How Coop works
                            </NavLink>
                            <NavLink
                                to="/getting-started/coop-hosted-simulations"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Hosted simulations
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                COOP
                            </p>
                            <NavLink
                                to="/home"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/content"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Content
                            </NavLink>
                            <NavLink
                                to="/explore"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Explore
                            </NavLink>
                            <NavLink
                                to="/create"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Create
                            </NavLink>
                            <NavLink
                                to="/home/projects"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Projects
                            </NavLink>
                            <NavLink
                                to="/home/remote-cache"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Remote Cache
                            </NavLink>
                            <NavLink
                                to="/home/remote-inference"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Remote Inference
                            </NavLink>
                            <NavLink
                                to="/home/api"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Coop API
                            </NavLink>
                            <NavLink
                                to="/home/purchases"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Purchases
                            </NavLink>
                            <NavLink
                                to="/home/profile"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Profile
                            </NavLink>
                            {isLoggedIn && (
                                <button
                                    onClick={handleLogout}
                                    className={standardNavItemStyles}
                                >
                                    <Power
                                        className="inline w-4 h-4 mr-2"
                                        strokeWidth="2.5"
                                    />
                                    Log out
                                </button>
                            )}
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                MORE INFORMATION
                            </p>
                            <a
                                href="https://docs.expectedparrot.com/en/latest/index.html"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Docs
                            </a>
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Discord
                            </a>
                            <NavLink
                                to="/about"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                About
                            </NavLink>
                        </div>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default Header;
