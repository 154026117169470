import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import InlineCode from '../../InlineCode';

function CoopHowItWorks() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Coop: How it works</h1>
                <p className="text-lg">
                    The Coop is a web application for storing and sharing your
                    work and exploring other AI-powered research.
                </p>
                <p>Steps for using the Coop:</p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    1. Create an account
                </h2>
                <p>
                    Go to the{' '}
                    <Link to="/login" className={linkStyles}>
                        Coop login page
                    </Link>{' '}
                    and select <span className="font-bold">Sign up</span>. You
                    can sign up using your email address and a password, or with
                    your Google/Microsoft account.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    2. Create a username
                </h2>
                <p>
                    Go to your{' '}
                    <Link to="/home/profile" className={linkStyles}>
                        Profile
                    </Link>{' '}
                    page and choose a username. Your username will be associated
                    with content that you post on the Coop. (You can change this
                    at any time, and you can also post content anonymously.)
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    3. Store your Expected Parrot API key
                </h2>
                <p>
                    Go to the <Link to="/home/api">API</Link> page of your
                    Account and copy your API key. You will need to provide this
                    key to EDSL to post, download and update objects on the
                    Coop.
                </p>
                <p>
                    Then add the following line to your{' '}
                    <InlineCode>.env</InlineCode> file in your current working
                    directory (this is the same file where you store API keys
                    for language models that you use with EDSL):
                    <br />
                    <br />
                    <InlineCode>
                        EXPECTED_PARROT_API_KEY = 'your_api_key_here'
                    </InlineCode>
                    <br />
                    <br />
                    This will load your API key as an environment variable that
                    EDSL can access. You can regenerate your API key (and update
                    your <InlineCode>.env</InlineCode> file) at any time.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    4. Create EDSL objects
                </h2>
                <p>
                    Create objects using the EDSL library:{' '}
                    <InlineCode>Agent</InlineCode>,{' '}
                    <InlineCode>AgentList</InlineCode>,{' '}
                    <InlineCode>Cache</InlineCode>,{' '}
                    <InlineCode>Jobs</InlineCode>,{' '}
                    <InlineCode>Results</InlineCode>,{' '}
                    <InlineCode>Scenario</InlineCode>,{' '}
                    <InlineCode>ScenarioList</InlineCode>, and{' '}
                    <InlineCode>Survey</InlineCode>
                    .
                    <br />
                    <br />
                    Coming soon: Features for uploading notebooks and other work
                    products!
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    5. Post objects to the Coop
                </h2>
                <p>
                    Post objects to the Coop using the{' '}
                    <InlineCode>edsl.coop.Coop</InlineCode> module or object
                    methods. See the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Coop documentation page
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    for details and examples of methods for uploading,
                    downloading and updating objects.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    6. Choose the visibility of your objects
                </h2>
                <p>
                    You can set the visibility of an object when you post it to
                    the Coop or update it later.
                    <br />
                    There are 3 options:
                    <br />
                </p>
                <ul className="list-disc list-inside space-y-2">
                    <li>
                        <InlineCode>public</InlineCode>: share with everyone
                    </li>
                    <li>
                        <InlineCode>private</InlineCode>: share with no one
                    </li>
                    <li>
                        <InlineCode>unlisted</InlineCode>: share with people who
                        have the link
                    </li>
                </ul>
                <p>
                    By default, objects are posted as unlisted.
                    <br />
                    See the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Coop documentation page
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    for details and examples for setting the visibility of an
                    object.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    7. Explore other users' content
                </h2>
                <p>
                    Search for other users' public content by object type,
                    keyword, author, topic, etc.
                    <br />
                    Copy code and examples to your own workspace to modify or
                    rerun them.
                </p>
                <p>
                    To access objects that are unlisted you must have the object
                    span <InlineCode>uuid</InlineCode> or the link to the
                    object.
                </p>
            </div>
        </>
    );
}

export default CoopHowItWorks;
