import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    const creditAmount = formData.get('credit_amount');

    const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ credits: creditAmount }),
    });

    if (response.ok) {
        const successData = await response.json();
        return redirect(successData.session_url);
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
