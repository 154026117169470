import { useState } from 'react';
import { useFetcher, useSubmit } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import { Play, Plus, Trash2, X } from 'react-feather';

interface Trait {
    id: string;
    name: string;
}

interface Agent {
    id: string;
    traits: Record<string, string>;
}

interface JobState {
    traits: Trait[];
    agents: Agent[];
}

function NewJobModal({
    projectId,
    children,
}: {
    projectId: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();
    const [jobState, setJobState] = useState<JobState>(() => {
        const traits = [
            { id: crypto.randomUUID(), name: 'age' },
            { id: crypto.randomUUID(), name: 'gender' },
            { id: crypto.randomUUID(), name: 'country' },
        ];

        const ages = [20, 30, 40, 50, 60];
        const genders = ['male', 'female'];
        const agents = [];

        for (let age of ages) {
            for (let gender of genders) {
                agents.push({
                    id: crypto.randomUUID(),
                    traits: {
                        [traits[0].id]: age.toString(),
                        [traits[1].id]: gender,
                        [traits[2].id]: 'USA',
                    },
                });
            }
        }
        return {
            traits,
            agents,
        };
    });

    function getNewJobFeedback() {
        if (!fetcher.data) {
            return;
        }
        if (fetcher.data.includes('success')) {
            return (
                <p className="text-sm text-green-600 dark:text-green-400">
                    {fetcher.data}
                </p>
            );
        } else {
            return (
                <p className="text-sm text-red-600 dark:text-red-600">
                    {fetcher.data}
                </p>
            );
        }
    }

    const addTrait = () => {
        const newTrait = { id: crypto.randomUUID(), name: '' };
        setJobState((prevState) => ({
            traits: [...prevState.traits, newTrait],
            agents: prevState.agents.map((agent) => ({
                ...agent,
                traits: { ...agent.traits, [newTrait.id]: '' },
            })),
        }));
    };

    const removeTrait = (id: string) => {
        setJobState((prevState) => ({
            traits: prevState.traits.filter((trait) => trait.id !== id),
            agents: prevState.agents.map((agent) => ({
                ...agent,
                traits: Object.fromEntries(
                    Object.entries(agent.traits).filter(
                        ([traitId]) => traitId !== id
                    )
                ),
            })),
        }));
    };

    const updateTrait = (id: string, name: string) => {
        setJobState((prevState) => ({
            ...prevState,
            traits: prevState.traits.map((trait) =>
                trait.id === id ? { ...trait, name } : trait
            ),
        }));
    };

    const addAgent = () => {
        const newAgent: Agent = {
            id: crypto.randomUUID(),
            traits: jobState.traits.reduce(
                (acc, trait) => ({ ...acc, [trait.id]: '' }),
                {}
            ),
        };
        setJobState((prevState) => ({
            ...prevState,
            agents: [...prevState.agents, newAgent],
        }));
    };

    const updateAgentTrait = (
        agentId: string,
        traitId: string,
        value: string
    ) => {
        setJobState((prevState) => ({
            ...prevState,
            agents: prevState.agents.map((agent) =>
                agent.id === agentId
                    ? {
                          ...agent,
                          traits: { ...agent.traits, [traitId]: value },
                      }
                    : agent
            ),
        }));
    };

    const removeAgent = (id: string) => {
        setJobState((prevState) => ({
            ...prevState,
            agents: prevState.agents.filter((agent) => agent.id !== id),
        }));
    };

    function handleSubmit() {
        const serializedAgentData = JSON.stringify(jobState);
        fetcher.submit(
            {
                agentData: serializedAgentData,
                intent: 'create_job',
                projectId: projectId,
            },
            { method: 'post', encType: 'application/json' }
        );
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="overflow-y-auto w-[90vw] max-w-[500px] h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-4 px-8 pt-4 pb-8 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Tabs.Root defaultValue="traits">
                        <Tabs.List
                            className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                            aria-label="Switch tabs"
                        >
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="traits"
                            >
                                Define traits
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="agents"
                            >
                                Configure agents
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="run"
                            >
                                Run
                            </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="traits">
                            <h3 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white">
                                Define traits
                            </h3>
                            <div className="space-y-4">
                                {jobState.traits.map((trait) => (
                                    <div
                                        key={trait.id}
                                        className="flex items-center space-x-2"
                                    >
                                        <input
                                            type="text"
                                            value={trait.name}
                                            onChange={(e) =>
                                                updateTrait(
                                                    trait.id,
                                                    e.target.value
                                                )
                                            }
                                            className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                        />
                                        <button
                                            onClick={() =>
                                                removeTrait(trait.id)
                                            }
                                            className="p-2 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                        >
                                            <Trash2 className="w-4 h-4" />
                                        </button>
                                    </div>
                                ))}
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={addTrait}
                                        className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                                    >
                                        <Plus className="w-4 h-4 mr-1" />
                                        Add new trait
                                    </button>
                                </div>
                            </div>
                        </Tabs.Content>
                        <Tabs.Content value="agents">
                            <h3 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white">
                                Configure agents
                            </h3>
                            <div className="space-y-4">
                                {jobState.agents.map((agent, index) => (
                                    <div
                                        key={agent.id}
                                        className="p-4 border rounded-lg dark:border-gray-600"
                                    >
                                        <div className="flex justify-between items-center mb-2">
                                            <h4 className="font-medium">
                                                Agent #{index + 1}
                                            </h4>
                                            <button
                                                onClick={() =>
                                                    removeAgent(agent.id)
                                                }
                                                className="p-1 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                                aria-label="Remove agent"
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </button>
                                        </div>
                                        {Object.entries(agent.traits).map(
                                            ([traitId, traitValue]) => {
                                                const trait =
                                                    jobState.traits.find(
                                                        (t) => t.id === traitId
                                                    );
                                                return (
                                                    <div
                                                        key={traitId}
                                                        className="mb-2"
                                                    >
                                                        <label className="block text-sm font-medium mb-1">
                                                            {trait.name ||
                                                                'unnamed trait'}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={traitValue}
                                                            onChange={(e) =>
                                                                updateAgentTrait(
                                                                    agent.id,
                                                                    traitId,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="w-full p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ))}
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={addAgent}
                                        className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                                    >
                                        <Plus className="w-4 h-4 mr-1" />
                                        Add new agent
                                    </button>
                                </div>
                            </div>
                        </Tabs.Content>
                        <Tabs.Content value="run">
                            <Dialog.Title asChild>
                                <h3 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white">
                                    Run with agents
                                </h3>
                            </Dialog.Title>
                            <Dialog.Description className="mb-4 text-sm">
                                Create a new remote inference job to run your
                                survey with AI agents. All jobs use the default
                                language model, gpt-4o-mini. More options will
                                be added in the future!
                            </Dialog.Description>
                            <div className="mb-4 overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3"
                                            >
                                                Agent
                                            </th>
                                            {jobState.traits.map((trait) => (
                                                <th
                                                    key={trait.id}
                                                    scope="col"
                                                    className="px-6 py-3"
                                                >
                                                    {trait.name}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobState.agents.map((agent, index) => (
                                            <tr
                                                key={agent.id}
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    Agent #{index + 1}
                                                </th>
                                                {jobState.traits.map(
                                                    (trait) => (
                                                        <td
                                                            key={trait.id}
                                                            className="px-6 py-4"
                                                        >
                                                            {
                                                                agent.traits[
                                                                    trait.id
                                                                ]
                                                            }
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-sm flex flex-col gap-4">
                                <input
                                    type="hidden"
                                    name="project_uuid"
                                    value={projectId}
                                />
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="flex items-center self-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    <Play className="w-4 mr-2" />
                                    Run
                                </button>
                                {getNewJobFeedback()}
                            </div>
                        </Tabs.Content>
                    </Tabs.Root>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default NewJobModal;
