import { useLoaderData } from 'react-router-dom';
import StatusDropdown from './StatusDropdown';
import Pagination from '../../../base/Pagination';
import RemoteJobTable from '../../../base/RemoteJobTable';

interface RemoteInferenceData {
    job_count: number;
    search_results_count: number;
    jobs: {
        created_ts: number;
        last_queued_ts: number;
        job_uuid: string;
        description: string;
        owner_email: string;
        results_status: string;
        results_uuid: string;
        price: number;
        status: string;
        logs: {
            created_ts: number;
            price: number;
            status: string;
            reason: string;
            description: string;
        }[];
    }[];
    current_page: number;
    page_size: number;
    total_pages: number;
    status_filters: string[] | null;
    status_counter: {
        queued: number;
        running: number;
        cancelling: number;
        cancelled: number;
        failed: number;
        completed: number;
    };
    is_authenticated: boolean;
    is_admin: boolean;
}

function RemoteInference() {
    const data = useLoaderData() as RemoteInferenceData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.search_results_count
    );

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Inference</h1>
                <p className="text-lg pb-2">
                    Manage your remote inference jobs and view your results.
                </p>
            </div>
            <div className="space-y-4 mt-8">
                <h2 className="font-bold text-2xl">History</h2>
                {data.search_results_count === 0 ? (
                    <p>Showing 0 jobs</p>
                ) : (
                    <p>
                        Showing {startIndex}-{endIndex} of {data.job_count} jobs
                    </p>
                )}
                <div className="flex justify-between">
                    <StatusDropdown />
                    <Pagination
                        style="grayscale"
                        currentPage={data.current_page}
                        totalPages={data.total_pages}
                    />
                </div>
                <RemoteJobTable jobs={data.jobs} isAdmin={data.is_admin} />
            </div>
        </>
    );
}

export default RemoteInference;
